<template>
    <CardComponent class="default-card">
        <template v-slot:cardHeader>
            <i class="iconfont icon-shujushangchuan" @click="handleOption('OperationUpload')"></i>
        </template>
        <template>
            2
        </template>
    </CardComponent>
</template>
<script>
import CardComponent from '../components/card'

export default {
    name: 'DefaultCard',
    components: {
        CardComponent
    },
    methods: {
        handleOption () {

        }
    }
}
</script>
<style lang="scss" scoped>

</style>
